define('m01new/controllers/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',

    selectedNodes: "",

    screwchanged: true,

    e1value: "e1 Value",
    e2value: "e2 Value",
    e3value: "e3 Value",

    e1visible: true,
    e2visible: true,
    e3visible: true,

    bauderMaster: true,

    infoText: "",
    keineSchraubenliste: true,

    init: function init() {

      this._super();

      var bauteile = this.controllerFor('bauteile');
      var application = this.controllerFor('application');
      var lasteinwirkung = this.controllerFor(application.get('lasteinwirkungsReiter'));
      if (!application.get('lasteinwirkungInvalid') || !application.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }

      if (application.get('bauder')) {
        this.bauderMaster = application.get('bauderMaster');
      }
    },

    watchInvalidScrewsCombo: (function () {

      var self = this;

      if (self.showinvalidscrews) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        var schraubenliste = self.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        var schraubenliste = self.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, false);
        self.set('schraubenliste', core);
      }
    }).observes('showinvalidscrews'),

    watchSelectedScrew: (function () {

      var self = this;
      var application = self.controllerFor('application');
      var downloadcenter = self.controllerFor('downloadcenter');

      var ergebnis = self.controllerFor('ergebnis');

      if (self.selectedNodes.length > 0) {

        if (self.selectedNodes[0].data.VM_Okay === "True") {

          console.log("Screw is valid.");

          application.set('verbindungsmittelInvalid', false);
          application.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          application.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          self.set('validScrewSelected', true);

          downloadcenter.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          downloadcenter.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          downloadcenter.set('validScrewSelected', true);

          ergebnis.set('pdfErstellt', false);

          if (!application.get('bauder')) {
            this.setScrewProperties(self.selectedNodes[0].data);
          }
          //this.ergebnisgrafik();
        } else {

            console.log("Screw is NOT valid.");

            application.set('verbindungsmittelInvalid', true);
            self.set('validScrewSelected', false);
            downloadcenter.set('validScrewSelected', false);
          }
      }
    }).observes('selectedNodes'),

    ergebnisgrafik: function ergebnisgrafik() {

      var self = this;

      var selectedScrew = self.selectedNodes[0].data.ArtNr;
      var cidGrafik = self.calcid;

      var application = self.controllerFor('application');
      var store = application.get('store');
      var arrayHelper = [];

      // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'schraube') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
      });

      $.ajax({
        type: "POST",
        url: server + "getGrafik/",
        data: {
          cid: cidGrafik,
          artnr: selectedScrew
        }
      }).done(function (data) {

        console.log('Daten der ausgewählten Schraube');
        console.log(data);

        var dataObject = JSON.parse(data);

        dataObject.screwProperty.forEach(function (item) {
          // console.log(item);
          var screwProp = store.push('screw-property', item);
          screwProp.save();
        });

        dataObject.translation.forEach(function (item) {

          var transl = store.push('translation', item);
          transl.save();

          var appearance = store.push('appearance', {
            id: item.id,
            transparency: 0,
            bauteile: [item.id]
          });
          appearance.save();

          var diffusecolor = store.push('diffusecolor', {
            id: item.id,
            rot: 0.51,
            gruen: 0.745,
            blau: 0.98,
            bauteile: [item.id]
          });
          diffusecolor.save();

          var bauteil = store.push('bauteil', {
            id: item.id,
            typ: item.id.substring(0, 8),
            translations: [item.id],
            rotations: [item.id],
            appearances: [item.id],
            diffusecolors: [item.id],
            screwProperties: [item.id]

          });
          bauteil.save();
          application.get('model').x3ddefault.objectAt(0).get('bauteile').pushObject(bauteil);

          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('translations').objectAt(0).set('x', item.x);
          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('translations').objectAt(0).set('y', item.y);
          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('translations').objectAt(0).set('z', item.z);
        });

        dataObject.rotation.forEach(function (item) {
          // console.log(item);
          var rotation = store.push('rotation', item);
          rotation.save();

          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('x', 0);
          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('y', 0);
          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('z', 0);
          application.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('winkel', item.winkel);
        });

        application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));
        application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
        application.get('model').x3ddefault.objectAt(0).set('abstaendeAnzeigen', true);
        application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'schraube');
      });
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');
        var selectedScrew;

        if (!application.get('bauderMaster') && application.get('bauder')) {
          selectedScrew = this.controllerFor(application.get('lasteinwirkungsReiter')).get('ergebnisschraube').ArtNr;
        } else {
          selectedScrew = self.selectedNodes[0].data.ArtNr;
        }

        console.log(cid);
        console.log('selectedScrew: ' + selectedScrew);

        applicationdata.set('selectedScrew', selectedScrew);

        self.set('loading', true);

        var server = application.get('server');
        var pfad = application.get('pfad');

        $.ajax({
          type: "POST",
          url: server + "getKompaktergebnis/",
          data: {
            cid: cid,
            artnr: selectedScrew
          }
        }).done(function (data, statusText, xhr) {

          self.controllerFor('supercontroller').logaufruf("getKompaktergebnis", xhr.status);

          var ergebnis = self.controllerFor('ergebnis');

          var downloadcenter = self.controllerFor('downloadcenter');
          downloadcenter.set('pdfErstellt', false);

          console.log(data, "index of div class='headline'", data.indexOf("div class='headline'"));

          var html = data;
          var bEnd, bStart;
          bStart = html.indexOf("<div class='headline'");
          bEnd = html.indexOf("</body");
          var body = html.slice(bStart, bEnd);

          ergebnis.set('kompaktergebnis', body.toString());
          ergebnis.set('pdfErstellt', false);
          ergebnis.send('setKompaktergebnis');
          self.transitionToRoute('ergebnis');
          self.set('loading', false);
        });
      },

      logNode: function logNode(event) {
        var self = this;
        console.log(self.selectedNodes);
        console.log(self.selectedNodes[0].text);
      }
    },

    vm_name: (function () {
      var value = this.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('ergebnisschraube').VM_Name;
      return value;
    }).property('screwchanged'),

    kopfform: (function () {
      var value = this.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('ergebnisschraube').Kopfform;
      return value;
    }).property('screwchanged'),

    abmessung: (function () {
      var d1 = parseFloat(this.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('ergebnisschraube').d_1.replace(",", "."));
      var l_s = parseInt(this.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('ergebnisschraube').l_s);

      var value = d1 + ' x ' + l_s;

      return value;
    }).property('screwchanged'),

    keinVerbindungsmittel: (function () {
      return Number.isNaN(parseFloat(this.controllerFor(this.controllerFor('application').get('lasteinwirkungsReiter')).get('ergebnisschraube').d_1));
    }).property('screwchanged'),

    artnr: (function () {
      // var value = this.controllerFor('lasteinwirkung').get('ergebnisschraube').ArtNr;
      return '';
    }).property('screwchanged'),

    setScrewProperties: function setScrewProperties(screwdata) {
      self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var headID = 5;
      switch (screwdata.Kopfform) {
        case 'Senkfräskopf':
          headID = 4;
          break;
        case 'Scheibenkopf':
          headID = 2;
          break;
        case 'Scheibenkopf II':
          headID = 11;
          break;
        case 'Zylinderkopf':
          headID = 6;
          break;
      }

      var threadlengthtip = 10;
      var threadlengthhead = 8;

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {

          item.get('screwProperties').objectAt(0).set('headformID', headID);

          var length = item.get('screwProperties').objectAt(0).get('length');

          switch (screwdata.VM_Typ) {
            case 'ASSY 3.0':
              if (length < 12) {
                threadlengthtip = length * 0.6;
              }
              item.get('screwProperties').objectAt(0).set('threadlengthtip', threadlengthtip);
              item.get('screwProperties').objectAt(0).set('threadlengthhead', 0);
              item.get('screwProperties').objectAt(0).set('headdiameter', 1.6);
              item.get('screwProperties').objectAt(0).set('headheight', 0.5);
              break;
            case 'ASSY 3.0 SK':
              if (length < 12) {
                threadlengthtip = length * 0.6;
              }
              item.get('screwProperties').objectAt(0).set('threadlengthtip', threadlengthtip);
              item.get('screwProperties').objectAt(0).set('threadlengthhead', 0);
              item.get('screwProperties').objectAt(0).set('headdiameter', 2.5);
              item.get('screwProperties').objectAt(0).set('headheight', 0.2);
              break;
            case 'ASSY 3.0 SK II':
              if (length < 12) {
                threadlengthtip = length * 0.6;
              }
              item.get('screwProperties').objectAt(0).set('threadlengthtip', threadlengthtip);
              item.get('screwProperties').objectAt(0).set('threadlengthhead', 0);
              item.get('screwProperties').objectAt(0).set('headdiameter', 2.5);
              item.get('screwProperties').objectAt(0).set('headheight', 0.2);
              break;
            case 'ASSY ISOTOP':
              if (length < 12) {
                threadlengthtip = length * 0.4;
                threadlengthhead = length * 0.3;
              }
              item.get('screwProperties').objectAt(0).set('threadlengthtip', threadlengthtip);
              item.get('screwProperties').objectAt(0).set('threadlengthhead', threadlengthhead);
              item.get('screwProperties').objectAt(0).set('headdiameter', 1);
              item.get('screwProperties').objectAt(0).set('headheight', 0.5);
              break;
            case 'ASSY plus VG':
              item.get('screwProperties').objectAt(0).set('threadlengthtip', length);
              item.get('screwProperties').objectAt(0).set('threadlengthhead', 0);
              item.get('screwProperties').objectAt(0).set('headdiameter', 1);
              item.get('screwProperties').objectAt(0).set('headheight', 0.5);
              break;
          }
        }
      });

      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }

  });

});