define('m01new/models/lasteinwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var lasteinwirkung = DS['default'].Model.extend({

		V_d: DS['default'].attr('string'),

		EindeckungText: DS['default'].attr('string'),
		g_ind: DS['default'].attr('string'),
		g: DS['default'].attr('string'),

		SLZ_ID: DS['default'].attr('string'),
		SLZ_Text: DS['default'].attr('string'),
		s_ind: DS['default'].attr('string'),
		sk: DS['default'].attr('string'),
		HuNN: DS['default'].attr('string'),
		NorddtTiefebene: DS['default'].attr('boolean'),
		Schneefanggitter: DS['default'].attr('boolean'),
		geoDatenManuell: DS['default'].attr('boolean'),
		ExponierteLage: DS['default'].attr('boolean'),

		Gelaende_ID: DS['default'].attr('Number'),
		aussergewoehnlichSchnee: DS['default'].attr('boolean'),
		Formbeiwert_DK: DS['default'].attr('boolean'),
		h0: DS['default'].attr('string'),

		entfKueste: DS['default'].attr('string'),

		WLZ: DS['default'].attr('string'),
		WindText: DS['default'].attr('string'),
		StandortID: DS['default'].attr('string'),
		Gelaendekategorie_Text: DS['default'].attr('string'),
		qWindDef: DS['default'].attr('string'),
		v_b0: DS['default'].attr('string'),
		q_p0: DS['default'].attr('string'),

		insel: DS['default'].attr('boolean'),
		Region_ID: DS['default'].attr('string'),
		rauhigkeitID: DS['default'].attr('string')

	});

	lasteinwirkung.reopenClass({
		FIXTURES: [{
			id: 1,
			V_d: "1.0",
			s_0: "0.0",

			insel: false,

			EindeckungText: "Dfl. Dachziegel/Dachsteine, Schiefer, 0,55 kN/m²",
			g_ind: 2,
			g: "0.55",

			SLZ_ID: 1,
			SLZ_Text: "SLZ 1",
			s_ind: 1,
			sk: 0,
			HuNN: "0",
			NorddtTiefebene: false,
			Schneefanggitter: false,
			geoDatenManuell: false,
			ExponierteLage: false,

			Gelaende_ID: 0,
			aussergewoehnlichSchnee: false,
			Formbeiwert_DK: false,
			h0: "0",
			entfKueste: "0",

			WLZ: 1,
			WindText: "leer",
			StandortID: 1,
			Gelaendekategorie_Text: "Binnenland",
			qWindDef: "",
			v_b0: "0",
			q_p0: "0",
			Region_ID: "1",
			rauhigkeitID: "1"

		}]
	});

	exports['default'] = lasteinwirkung;

});