define('m01new/controllers/daemmung', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    debug: true,

    bauder: false,

    parentNode: "",

    master: false,
    mde: false,
    zweilagig: false,
    freieEingabeGL: false,
    osbSelected: false,
    infoFeldAnzeigen: false,
    grundlatteMaterial: 0,
    verlegungLagen: false,
    grundlatteAbmessungen: 2,

    glDickeAktiv: false,
    sAbstandAktiv: false,
    glLaengeAktiv: false,
    dsNameAktiv: false,
    dsS10Aktiv: false,
    dsEmodAktiv: false,

    daemstoffbezeichnung: "",
    daemstoffdickegesamt: 0,

    daemmstoffart_1: "",
    daemmstoffartnr_1: "",
    daemmstoffdicken_1: "",
    daemmstoffdruckfest_1: true,
    daemmstoffemod_1: "",
    daemmstoffgew_1: 0,
    daemmstoffhersteller_1: "",
    daemmstoffind_1: 0,
    daemmstoffs_10_1: "",
    daemmstofftext_1: "",

    daemmstoffart_2: "",
    daemmstoffartnr_2: "",
    daemmstoffdicken_2: "",
    daemmstoffdruckfest_2: true,
    daemmstoffemod_2: "0",
    daemmstoffgew_2: 0,
    daemmstoffhersteller_2: "",
    daemmstoffind_2: 0,
    daemmstoffs_10_2: "",
    daemmstofftext_2: "",

    openFromFile: false,
    displayErrors: true,

    glDicke: "",

    traufkonstruktion: false,
    befestigungsvariante: 0,
    verklebt: false,

    daemmungLage1: "",
    daemmungLage2: "",

    validations: {
      glHoehe: {
        numericality: {
          greaterThanOrEqualTo: 30,
          lessThanOrEqualTo: 240
        }
      },
      glBreite: {
        numericality: {
          greaterThanOrEqualTo: 50,
          lessThanOrEqualTo: 1250
        }
      },
      glLaenge: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 9999.0
        }
      },
      // daemmstoffdicken_1: {
      //   numericality: {
      //     greaterThanOrEqualTo: 15.0,
      //     lessThanOrEqualTo: 400.0
      //   }
      // },
      daemstoffdickegesamt: {
        numericality: {
          greaterThanOrEqualTo: 15.0,
          lessThanOrEqualTo: 400.0
        }
      },
      daemmstoffemod_1: {
        numericality: {
          greaterThanOrEqualTo: 0.5,
          lessThanOrEqualTo: 15000.0
        }
      },
      daemmstoffs_10_1: {
        numericality: {
          greaterThanOrEqualTo: 0.05,
          lessThanOrEqualTo: 9999.0
        }
      },

      sAbstand: {
        numericality: {
          greaterThanOrEqualTo: 50,
          lessThanOrEqualTo: 175
        }
      },
      glDicke: {
        numericality: {
          greaterThanOrEqualTo: 22,
          lessThanOrEqualTo: 240
        }
      }
    },

    ttDicke: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 15 - 400 [mm]";
    }),
    ttEmod: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.5 - 15000 [N/mm²]";
    }),
    ttSigma10: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.05 - 9999.0 [N/mm²]";
    }),
    ttGLHoehe: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 30 - 240 [mm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttGLBreite: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 50 - 1250 [mm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttGLLaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 9999.0 [m]";
    }),
    ttMaxAbstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 50 - 175 [cm]";
    }),
    ttGLDicke: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 22 - 240 [mm]";
    }),
    i18n: Ember['default'].inject.service(),

    bvariante: [],
    bvarianteContent: [{
      name: "kont_verschraubung",
      id: 0,
      disabled: false
    }, {
      name: "traufkonstruktion",
      id: 1,
      disabled: false
    }],

    verlegung: [],
    verlegungContent: [{
      name: "Einlagige Verlegung",
      id: false,
      disabled: false
    }, {
      name: "Zweilagige Verlegung",
      id: true,
      disabled: false
    }],

    glMaterial: [],
    glMaterialContent: [{
      name: "nh_c24",
      id: 0,
      disabled: false
    }, {
      name: "osb",
      id: 1,
      disabled: false
    }],

    glVerklebt: [],
    glVerklebtContent: [{
      name: "aufkaschiert_verklebt",
      id: true,
      disabled: false
    }, {
      name: "schwimmend_lose",
      id: false,
      disabled: false
    }],

    glAbmessungen: [],
    glAbmessungenContent: [{
      name: "gla_30x50",
      id: 1,
      disabled: false
    }, {
      name: "gla_40x60",
      id: 2,
      disabled: false
    }, {
      name: "gla_40x80",
      id: 3,
      disabled: false
    }, {
      name: "gla_60x60",
      id: 4,
      disabled: false
    }, {
      name: "gla_40x100",
      id: 5,
      disabled: false
    }, {
      name: "freie_eingabe",
      id: 0,
      disabled: false
    }],

    glContent: [],
    glHoehen: {
      1: "3",
      2: "4",
      3: "4",
      4: "6",
      5: "4"
    },
    glBreiten: {
      1: "5",
      2: "6",
      3: "8",
      4: "6",
      5: "10"
    },

    dsDruckfest: [],
    dsDruckfestContent: [{
      name: "druckweich",
      id: false,
      disabled: false
    }, {
      name: "druckfest",
      id: true,
      disabled: false
    }],

    selectedNodesLage1: "",
    selectedNodesLage2: "",
    loadingLage1: false,
    loadingLage2: false,

    glBreite: "",
    glHoehe: "",
    glLaenge: "",
    sAbstand: "",

    SelectedVarId: "",

    init: function init() {

      this._super();

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      // console.log(('init').toUpperCase());

      this.set('glHoehe', "40.0");
      this.set('glBreite', "60.0");
      this.set('glDicke', "22.0");
      this.set('glLaenge', "0.0");
      this.set('sAbstand', "175.0");

      this.set('daemstoffdickegesamt', "");
      this.set('daemmstoffemod_1', "");
      this.set('daemmstoffs_10_1', "");

      x3d.set('glbreiteEingetragen', true);
      x3d.set('glhoeheEingetragen', true);
      x3d.set('transformHelper', !x3d.get('transformHelper'));

      this.set('bauder', application.get('bauder'));
      this.set('SelectedVarId', "0");

      if (application.get('bauder')) {

        this.set('bauder', true);

        if (application.get('bauderMaster')) {
          this.set('master', true);
        } else {
          this.set('master', false);
        }

        this.validations.glHoehe.numericality.greaterThanOrEqualTo = 40;
        this.validations.glBreite.numericality.greaterThanOrEqualTo = 60;

        this.set('ttGLHoehe', this.get('i18n').t('wertebereich') + " 40 - 240 [mm]");
        this.set('ttGLBreite', this.get('i18n').t('wertebereich') + " 60 - 1250 [mm]");
      } else {
        this.set('bauder', false);
        this.set('master', true);
      }

      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.get('debug')) {
        console.log("values in daemmung: ");
        console.log(values);
      }

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      this.set('openFromFile', true);

      this.set('befestigungsvariante', parseInt(values.befestigungsVariante));
      this.set('daemmstoffdruckfest_1', values.DaeDruckfest);

      this.set('mde', values.mde);

      this.set('grundlatteMaterial', parseInt(values.grundlatteMaterial));

      this.set('MatKeyTimberElementGl', parseInt(values.MatKeyTimberElementGl));
      this.set('FKLKeyTimberElementGl', parseInt(values.FKLKeyTimberElementGl));

      this.set('openFromFile', false);
      this.watchBefestigungsvariante();
      this.set('openFromFile', true);

      var d_dicke_1 = parseFloat(values.d_Dae1);
      var d_dicke_2 = parseFloat(values.d_Dae2);
      var d_dicke_ges = d_dicke_1 * 10 + d_dicke_2 * 10;

      if (String(values.NameDae2) === "") {
        this.set('daemstoffbezeichnung', String(values.NameDae1) + ' ' + parseInt(d_dicke_1));
      } else {
        this.set('daemstoffbezeichnung', String(values.NameDae1 + ' ' + parseInt(d_dicke_1) + ' + ' + String(values.NameDae2) + ' ' + parseInt(d_dicke_2)));
      }

      this.set('daemmstoffart_1', String(values.NameDae1));

      d_dicke_ges = d_dicke_ges.toFixed(1);
      this.set('daemstoffdickegesamt', parseInt(d_dicke_ges));

      daemmungdata.set('d_Dae1', values.d_Dae1);
      daemmungdata.set('d_Dae2', values.d_Dae2);

      var emod_1 = parseFloat(values.E_Dae) * 10;
      emod_1 = emod_1.toFixed(2);
      this.set('daemmstoffemod_1', emod_1);
      this.set('daemmstoffartnr_1', values.ArtNr1.toString());
      this.set('daemmstoffs_10_1', parseFloat(values.Sima10) * 10);

      this.set('daemmstoffart_2', String(values.NameDae2));
      this.set('daemmstoffdicken_2', d_dicke_2);
      this.set('daemmstoffartnr_2', values.ArtNr2);

      this.set('osbVerklebt', values.verklebt);

      this.set('grundlatteAbmessungen', parseInt(values.grundlatteAbmessungID));

      var bauteile = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile');
      bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('y', d_dicke_1);
      bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).set('y', d_dicke_2);

      bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).set('y', d_dicke_ges / 10);
      bauteile.findBy('id', 'traufkonstruktion2').get('boxsizes').objectAt(0).set('y', d_dicke_ges / 10);

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('daemmstoffDickeEingetragen', true);

      this.set('glBreite', (parseFloat(values.glBreite) * 10).toFixed(1));
      this.setX3D(parseInt(values.glBreite) * 10, { target: { name: "glBreite" } });

      if (parseInt(values.grundlatteMaterial) === 0) {

        // console.log(('glHoehe').toUpperCase());
        this.setX3D(parseInt(values.glHoehe) * 10, { target: { name: "glHoehe" } });

        this.set('glHoehe', (parseFloat(values.glHoehe) * 10).toFixed(1));
      } else if (parseInt(values.grundlatteMaterial) === 1) {
        this.set('glDicke', (parseFloat(values.glHoehe) * 10).toFixed(1));
      }

      this.set('glLaenge', (parseFloat(values.L_Gl) / 100).toFixed(1));
      this.set('sAbstand', parseFloat(values.schraubenabstaende).toFixed(1));

      this.set('SelectedVarId', parseInt(values.SelectedVarId));
      daemmungdata.set('SelectedVarId', parseInt(values.SelectedVarId));

      this.send('validation');

      this.set('openFromFile', false);

      this.send('getDaemmstoffbaum');
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setBfVarianten();
      // self.setDaemmstoffLagen();
      self.setDaemmstoffDruckfest();
      self.setGlMaterialien();
      self.setGrundlattenBefestigung();
      self.setGrundlattenabmessungen();
    },

    setBfVarianten: function setBfVarianten() {
      var self = this;
      var indices = [];
      var application = this.controllerFor('application');
      var dachgeometrie = this.controllerFor('dachgeometrie');

      if (application.get('bauder') || dachgeometrie.get('konstruktion') === 1) {
        indices = [0];
      } else {
        indices = [0, 1];
      }
      self.set('bvariante', self.getSelectFieldContent('bvarianteContent', indices));
    },

    setDaemmstoffLagen: function setDaemmstoffLagen() {
      var self = this;
      var indices = [false, true];
      self.set('verlegung', self.getSelectFieldContent('verlegungContent', indices));
    },

    setDaemmstoffDruckfest: function setDaemmstoffDruckfest() {
      var self = this;
      var indices = [false, true];
      self.set('dsDruckfest', self.getSelectFieldContent('dsDruckfestContent', indices));
    },

    setGlMaterialien: function setGlMaterialien() {
      var self = this;
      var indices = [0, 1];
      self.set('glMaterial', self.getSelectFieldContent('glMaterialContent', indices));
    },

    setGrundlattenBefestigung: function setGrundlattenBefestigung() {
      var self = this;
      var indices = [true, false];
      self.set('glVerklebt', self.getSelectFieldContent('glVerklebtContent', indices));
    },

    setGrundlattenabmessungen: function setGrundlattenabmessungen() {
      var self = this;
      var indices = [];
      var application = this.controllerFor('application');

      if (application.get('bauder')) {
        indices = [2, 3, 4, 5, 0];
      } else {
        indices = [1, 2, 3, 4, 5, 0];
      }
      self.set('glAbmessungen', self.getSelectFieldContent('glAbmessungenContent', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }
      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      var self = this;
      switch (db) {
        case 'bvarianteContent':
          dbContent = self.get('bvarianteContent');
          break;
        case 'verlegungContent':
          dbContent = self.get('verlegungContent');
          break;
        case 'dsDruckfestContent':
          dbContent = self.get('dsDruckfestContent');
          break;
        case 'glMaterialContent':
          dbContent = self.get('glMaterialContent');
          break;
        case 'glVerklebtContent':
          dbContent = self.get('glVerklebtContent');
          break;
        case 'glAbmessungenContent':
          dbContent = self.get('glAbmessungenContent');
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchBefestigungsvariante: (function () {

      var self = this;

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var unterkonstruktion = this.controllerFor('unterkonstruktion');
      var gebaeudemasse = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);
      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      if (Number(this.controllerFor('application').get('model').unterkonstruktion.objectAt(0).get('UK_ind')) !== 2) {
        if (self.get('befestigungsvariante') === 0) {
          self.set('traufkonstruktion', false);
          gebaeudemasse.set('traufkonstruktion', false);
          if (self.get('daemmstoffdruckfest_1') === true) {
            x3d.set('aktuellerSchraubenTyp', 'schraube2');
            self.set('SelectedVarId', "0");
            daemmungdata.set('SelectedVarId', "0");
          } else {
            x3d.set('aktuellerSchraubenTyp', 'schraube1');
            self.set('SelectedVarId', "3");
            daemmungdata.set('SelectedVarId', "3");
          }
        } else {
          self.set('traufkonstruktion', true);
          gebaeudemasse.set('traufkonstruktion', true);
          unterkonstruktion.setSchraubenEigenschaften();
          x3d.set('aktuellerSchraubenTyp', 'schraube3');
          self.set('SelectedVarId', "4");
          daemmungdata.set('SelectedVarId', "4");
        }
        self.send('validation', self.get('glHoehe'), { target: { name: "glHoehe" } });

        x3d.set('transformHelper', !x3d.get('transformHelper'));
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

        daemmungdata.set('befestigungsVariante', self.get('befestigungsvariante'));
      }
    }).observes('befestigungsvariante'),

    watchVerlegung: (function () {

      var self = this;

      if (self.get('verlegungLagen') === true) {
        self.set('zweilagig', true);

        //self.send('getDaemmstoffbaum');
      } else {
          self.set('zweilagig', false);

          self.set('daemmstoffart_2', '');
          //self.set('daemmstoffartnr_2', self.selectedNodesLage2[0].data.artnr);
          self.set('daemmstoffartnr_2', '');
          self.set('daemmstoffdicken_2', '');
          self.set('daemmstoffdruckfest_2', '');
          self.set('daemmstoffemod_2', '');
          self.set('daemmstoffgew_2', '');
          self.set('daemmstoffhersteller_2', '');
          self.set('daemmstoffind_2', '');
          self.set('daemmstoffs_10_2', '');
          self.set('daemmstofftext_2', '');

          var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

          daemmungdata.set('NameDae2', '');
          //daemmungdata.set('ArtNr2', self.get('daemmstoffartnr_2'));
          daemmungdata.set('ArtNr2', '');
          daemmungdata.set('d_Dae2', '0.0');
        }
    }).observes('verlegungLagen'),

    watchGrundlattenGrenzwertmodus: (function () {
      // var self = this;
      //
      // console.log('watchGrundlattenGrenzwertmodus');
      // console.log('befestigungsvariante: '+self.get('befestigungsvariante'));
      // console.log('daemmstoffdruckfest_1: '+self.get('daemmstoffdruckfest_1'));
      //
      // if (self.get('befestigungsvariante') === 1 && self.get('daemmstoffdruckfest_1') === false) {
      //   console.log('if-zweig');
      //   self.set('validations.glHoehe.numericality.greaterThanOrEqualTo', 50);
      //   self.set('ttGLHoehe', self.get('i18n').t('wertebereich') + "50 - 240 [mm]");
      //   self.set('glHoehe', (50).toFixed(1));
      // } else {
      //   console.log('else-zweig');
      //   self.set('validations.glHoehe.numericality.greaterThanOrEqualTo', 30);
      //   self.set('ttGLHoehe', self.get('i18n').t('wertebereich') + "30 - 240 [mm]");
      //   self.set('glHoehe', (40).toFixed(1));
      // }

      // self.send('validation');
    }).observes('daemmstoffdruckfest_1', 'befestigungsvariante'),

    watchDruckfest: (function () {

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var self = this;

      if (self.get('daemmstoffdruckfest_1') === true) {
        self.set('daemmstoffdruckfest_1', true);
        if (self.get('SelectedVarId') !== "4") {
          self.set('SelectedVarId', "0");
        }
        if (self.get('befestigungsvariante') === 0) {
          x3d.set('aktuellerSchraubenTyp', 'schraube2');
          // console.log('*** watchDruckfest --> schraube2 ***');
        }

        if (!self.get('bauder')) {
          self.set('daemmstoffemod_1', '');
          self.set('daemmstoffs_10_1', '');

          self.set('validations.daemmstoffemod_1.numericality.greaterThanOrEqualTo', 0.5);
          self.set('validations.daemmstoffs_10_1.numericality.greaterThanOrEqualTo', 0.05);
        }
        self.get('glMaterialContent').findBy('name', 'osb').disabled = false;
      } else {
        self.set('daemmstoffdruckfest_1', false);
        if (self.get('SelectedVarId') !== "4") {
          self.set('SelectedVarId', "3");
        }
        if (self.get('befestigungsvariante') === 0) {
          x3d.set('aktuellerSchraubenTyp', 'schraube1');
        }

        if (!self.get('bauder')) {
          self.set('daemmstoffemod_1', '0');
          self.set('daemmstoffs_10_1', '0');

          self.set('validations.daemmstoffemod_1.numericality.greaterThanOrEqualTo', 0);
          self.set('validations.daemmstoffs_10_1.numericality.greaterThanOrEqualTo', 0);
        }

        self.get('glMaterialContent').findBy('name', 'osb').disabled = true;
      }

      self.send('validation', self.get('glHoehe'), { target: { name: "glHoehe" } });

      self.setGlMaterialien();

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);
      daemmungdata.set('DaeDruckfest', self.get('daemmstoffdruckfest_1'));
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }).observes('daemmstoffdruckfest_1'),

    watchGLMaterial: (function () {
      var self = this;

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      if (self.get('grundlatteMaterial') === 1) {

        self.set('grundlatteAbmessungen', 2);

        self.set('osbSelected', true);
        self.set('mde', true);
        daemmungdata.set('MatKeyTimberElementGl', 20);
        daemmungdata.set('FKLKeyTimberElementGl', 126);
        this.set('glDicke', "22.0");

        x3d.get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 2.2);
        x3d.get('bauteile').findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 2.2);

        daemmungdata.set('glMaterial', "1");
        daemmungdata.set('grundlatteMaterial', "1");
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('grundlatteMaterial', true);

        self.get('dsDruckfestContent').findBy('name', 'druckweich').disabled = true;
      } else {

        self.set('osbSelected', false);
        self.set('mde', false);
        daemmungdata.set('MatKeyTimberElementGl', 0);
        daemmungdata.set('FKLKeyTimberElementGl', 5);

        x3d.get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', parseFloat(self.glHoehen[self.get('grundlatteAbmessungen')]));
        x3d.get('bauteile').findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', parseFloat(self.glHoehen[self.get('grundlatteAbmessungen')]));

        daemmungdata.set('glMaterial', "0");
        daemmungdata.set('grundlatteMaterial', "0");
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('grundlatteMaterial', false);

        self.get('dsDruckfestContent').findBy('name', 'druckweich').disabled = false;
      }

      self.setDaemmstoffDruckfest();

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('farbeGeandert', !x3d.get('farbeGeandert'));
    }).observes('grundlatteMaterial'),

    watchOSB: (function () {

      var self = this;

      if (self.get('osbVerklebt') === true) {
        self.set('verklebt', true);
      } else {
        self.set('verklebt', false);
      }

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      daemmungdata.set('verklebt', self.get('osbVerklebt'));
    }).observes('osbVerklebt'),

    watchGrundlatteAbmessungen: (function () {

      var self = this;

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      // console.log(('watchGrundlatteAbmessungen').toUpperCase());

      if (this.get('grundlatteAbmessungen') === 1) {
        this.set('freieEingabeGL', false);
        bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 3);
        bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 3);
        bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 5);
        bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 5);
        self.set('glHoehe', (Number(self.glHoehen[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
        self.set('glBreite', (Number(self.glBreiten[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
        this.setGrundlattenmasseEingetragen();
        // name: "40 mm x 60 mm",
      } else if (this.get('grundlatteAbmessungen') === 2) {
          this.set('freieEingabeGL', false);
          bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
          bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
          bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 6);
          bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 6);
          self.set('glHoehe', (Number(self.glHoehen[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
          self.set('glBreite', (Number(self.glBreiten[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
          this.setGrundlattenmasseEingetragen();
          // name: "40 mm x 60 mm",
        } else if (this.get('grundlatteAbmessungen') === 3) {
            this.set('freieEingabeGL', false);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 8);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 8);
            self.set('glHoehe', (Number(self.glHoehen[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
            self.set('glBreite', (Number(self.glBreiten[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
            this.setGrundlattenmasseEingetragen();
            // name: "40 mm x 80 mm",
          } else if (this.get('grundlatteAbmessungen') === 4) {
              this.set('freieEingabeGL', false);
              bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 6);
              bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 6);
              bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 6);
              bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 6);
              self.set('glHoehe', (Number(self.glHoehen[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
              self.set('glBreite', (Number(self.glBreiten[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
              this.setGrundlattenmasseEingetragen();
              // name: "60 mm x 60 mm",
            } else if (this.get('grundlatteAbmessungen') === 5) {
                this.set('freieEingabeGL', false);
                bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 4);
                bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 4);
                bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', 10);
                bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', 10);
                self.set('glHoehe', (Number(self.glHoehen[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
                self.set('glBreite', (Number(self.glBreiten[self.get('grundlatteAbmessungen')]) * 10).toFixed(1));
                this.setGrundlattenmasseEingetragen();
                // name: "40 mm x 100 mm",
              } else if (this.get('grundlatteAbmessungen') === 0) {
                  this.set('freieEingabeGL', true);
                  self.set('glHoehe', "");
                  self.set('glBreite', "");
                  x3d.set('glhoeheEingetragen', false);
                  x3d.set('glbreiteEingetragen', false);
                }

      daemmungdata.set('gl_ind', self.get('grundlatteAbmessungen'));
      daemmungdata.set('grundlatteAbmessungID', self.get('grundlatteAbmessungen'));

      x3d.set('transformHelper', !x3d.get('transformHelper'));

      this.send('validation', self.get('glHoehe'), { target: { name: "glHoehe" } });
    }).observes('grundlatteAbmessungen'),

    watchSelectedNodes: (function () {

      var self = this;

      var application = self.controllerFor('application');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (self.selectedNodesLage1.length !== 0 && application.get('bauder') === false) {
        self.set('daemmstoffart_1', self.selectedNodesLage1[0].data.art);
        self.set('daemmstoffartnr_1', self.selectedNodesLage1[0].data.artnr);
        // self.set('daemmstoffartnr_1', '');

        console.log(self.selectedNodesLage1[0].data);

        self.set('daemmstoffdicken_1', Number(self.selectedNodesLage1[0].data.dicken).toFixed(1));
        bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('y', self.selectedNodesLage1[0].data.dicken / 10);
        self.set('daemmstoffdruckfest_1', self.selectedNodesLage1[0].data.druckfest);

        if (self.selectedNodesLage1[0].data.druckfest === true) {
          self.set('validations.daemmstoffemod_1.numericality.greaterThanOrEqualTo', 0.5);
          self.set('validations.daemmstoffs_10_1.numericality.greaterThanOrEqualTo', 0.05);
        } else {
          self.set('validations.daemmstoffemod_1.numericality.greaterThanOrEqualTo', 0);
          self.set('validations.daemmstoffs_10_1.numericality.greaterThanOrEqualTo', 0);
        }

        self.set('daemmstoffemod_1', parseFloat(self.selectedNodesLage1[0].data.emod).toFixed(2));
        self.set('daemmstoffgew_1', self.selectedNodesLage1[0].data.gew);
        self.set('daemmstoffhersteller_1', self.selectedNodesLage1[0].data.hersteller);
        self.set('daemmstoffind_1', self.selectedNodesLage1[0].data.ind);
        self.set('daemmstoffs_10_1', parseFloat(self.selectedNodesLage1[0].data.s_10).toFixed(2));
        self.set('daemmstofftext_1', self.selectedNodesLage1[0].data.text);

        var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

        daemmungdata.set('NameDae1', self.get('daemmstoffart_1'));

        daemmungdata.set('d_Dae1', parseFloat(self.get('daemmstoffdicken_1')).toFixed(1) / 10);

        daemmungdata.set('ArtNr1', '');

        daemmungdata.set('E_Dae', self.get('daemmstoffemod_1') / 10);
        // daemmungdata.set('Sima10', self.get('daemmstoffs_10_1')/10);
        daemmungdata.set('DaeDruckfest', self.get('daemmstoffdruckfest_1'));

        var application = self.controllerFor('application');
        application.set('daemmungInvalid', false);

        if (self.get('daemmstoffdruckfest_1') === true) {
          self.set('daemmstoffdruckfest_1', true);
          if (self.get('SelectedVarId') !== "4") {
            self.set('SelectedVarId', "0");
          }
          self.set('infoFeldAnzeigen', true);
          if (self.get('befestigungsvariante') === 0) {
            x3d.set('aktuellerSchraubenTyp', 'schraube2');
          }
        } else {
          self.set('daemmstoffdruckfest_1', false);
          if (self.get('SelectedVarId') !== "4") {
            self.set('SelectedVarId', "3");
          }
          if (self.get('befestigungsvariante') === 0) {
            x3d.set('aktuellerSchraubenTyp', 'schraube1');
          }
        }

        self.set('daemstoffbezeichnung', self.get('daemmstoffart_1'));

        self.set('daemstoffdickegesamt', parseInt(self.get('daemmstoffdicken_1')));
      } else {

        if (self.selectedNodesLage1.length !== 0 && self.selectedNodesLage1[0].data.lage === 1) {

          console.log("LAGE 1");

          // self.set('daemmstoffart_1', ('BauderPIR ' + self.selectedNodesLage1[0].data.art));
          self.set('daemmstoffart_1', self.selectedNodesLage1[0].data.art);
          self.set('daemmstoffartnr_1', self.selectedNodesLage1[0].data.artnr);
          // self.set('daemmstoffartnr_1', '');
          self.set('daemmstoffdicken_1', Number(this.checkDickeMDE(self.selectedNodesLage1[0].data)).toFixed(1));
          bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('y', this.checkDickeMDE(self.selectedNodesLage1[0].data) / 10);
          self.set('daemmstoffdruckfest_1', self.selectedNodesLage1[0].data.druckfest);
          self.set('daemmstoffemod_1', parseFloat(self.selectedNodesLage1[0].data.emod).toFixed(2));
          self.set('daemmstoffgew_1', self.selectedNodesLage1[0].data.gew);
          self.set('daemmstoffhersteller_1', self.selectedNodesLage1[0].data.hersteller);
          self.set('daemmstoffind_1', self.selectedNodesLage1[0].data.ind);
          self.set('daemmstoffs_10_1', parseFloat(self.selectedNodesLage1[0].data.s_10).toFixed(2));
          self.set('daemmstofftext_1', self.selectedNodesLage1[0].data.text);

          var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

          // daemmungdata.set('NameDae1', ('BauderPIR ' + self.selectedNodesLage1[0].data.art));

          daemmungdata.set('d_Dae1', parseFloat(this.checkDickeMDE(self.selectedNodesLage1[0].data)).toFixed(1) / 10);
          // daemmungdata.set('d_Dae1', parseFloat(self.get('daemmstoffdicken_1')).toFixed(1) / 10);

          daemmungdata.set('ArtNr1', self.selectedNodesLage1[0].data.artnr);
          // daemmungdata.set('ArtNr1', '');

          if (self.get('daemmstoffart_1').indexOf("MDE") !== -1) {
            console.log("mde selected.");
            self.set('glLaenge', "1.18");
            self.set('mde', true);
            self.set('grundlatteMaterial', 1);
            daemmungdata.set('mde', true);
            daemmungdata.set('L_Gl', "118");
            daemmungdata.set('MatKeyTimberElementGl', 20);
            daemmungdata.set('FKLKeyTimberElementGl', 126);
            this.set('glDicke', "22.0");
            this.set('osbVerklebt', true);

            x3d.get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 2.2);
            x3d.get('bauteile').findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 2.2);

            daemmungdata.set('d_Dae1', this.checkDickeMDE(self.selectedNodesLage1[0].data) / 10);
            //daemmungdata.set('d_Dae1', (parseFloat(self.get('daemmstoffdicken_1')) -22) / 10);
          } else {
              console.log("mde not selected.");
              self.set('glLaenge', "5.0");
              self.set('mde', false);
              self.set('grundlatteMaterial', 0);
              daemmungdata.set('mde', false);
              daemmungdata.set('L_Gl', "0");
              daemmungdata.set('MatKeyTimberElementGl', 0);
              daemmungdata.set('FKLKeyTimberElementGl', 5);

              //daemmungdata.set('d_Dae1', parseFloat(self.get('daemmstoffdicken_1')) / 10);
            }

          daemmungdata.set('E_Dae', self.get('daemmstoffemod_1') / 10);
          // daemmungdata.set('Sima10', self.get('daemmstoffs_10_1')/10);
          daemmungdata.set('DaeDruckfest', self.get('daemmstoffdruckfest_1'));

          var application = self.controllerFor('application');
          application.set('daemmungInvalid', false);

          self.set('zweilagig', false);

          self.set('daemmstoffart_2', '');
          //self.set('daemmstoffartnr_2', self.selectedNodesLage2[0].data.artnr);
          self.set('daemmstoffartnr_2', '');
          self.set('daemmstoffdicken_2', '');
          bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).set('y', 0);
          self.set('daemmstoffdruckfest_2', '');
          self.set('daemmstoffemod_2', '');
          self.set('daemmstoffgew_2', '');
          self.set('daemmstoffhersteller_2', '');
          self.set('daemmstoffind_2', '');
          self.set('daemmstoffs_10_2', '');
          self.set('daemmstofftext_2', '');

          daemmungdata.set('NameDae2', '');
          //daemmungdata.set('ArtNr2', self.get('daemmstoffartnr_2'));
          daemmungdata.set('ArtNr2', '');
          daemmungdata.set('d_Dae2', '0.0');

          if (!self.get('master')) {
            this.setSparrenabstand(self.selectedNodesLage1[0].data.artnr);
          }

          // self.set('daemstoffbezeichnung', self.get('daemmstoffart_1')+' '+parseInt(self.get('daemmstoffdicken_1')));
          self.set('daemstoffbezeichnung', self.get('daemmstoffart_1') + ' ' + parseInt(Number(self.selectedNodesLage1[0].data.dicke)));

          self.set('daemstoffdickegesamt', parseInt(self.get('daemmstoffdicken_1')));
        } else if (self.selectedNodesLage1.length !== 0 && self.selectedNodesLage1[0].data.lage === 2) {

          console.log("LAGE 2");

          var self = this;
          var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

          self.get('jstreeActionReceiver1').send('getNode', self.selectedNodesLage1[0].parents[1]);

          var pNode = self.parentNode;

          self.set('daemmstoffart_1', pNode.data.art);
          self.set('daemmstoffartnr_1', pNode.data.artnr);
          // self.set('daemmstoffartnr_1', '');
          self.set('daemmstoffdicken_1', Number(this.checkDickeMDE(pNode.data)).toFixed(1));
          self.set('daemmstoffdruckfest_1', pNode.data.druckfest);
          self.set('daemmstoffemod_1', parseFloat(pNode.data.emod).toFixed(2));
          self.set('daemmstoffgew_1', pNode.data.gew);
          self.set('daemmstoffhersteller_1', pNode.data.hersteller);
          self.set('daemmstoffind_1', pNode.data.ind);
          self.set('daemmstoffs_10_1', parseFloat(pNode.data.s_10).toFixed(2));
          self.set('daemmstofftext_1', pNode.data.text);

          // daemmungdata.set('NameDae1', ('BauderPIR ' + self.get('daemmstoffart_1')));
          daemmungdata.set('d_Dae1', parseFloat(this.checkDickeMDE(pNode.data)).toFixed(1) / 10);
          // daemmungdata.set('d_Dae1', parseFloat(self.get('daemmstoffdicken_1')).toFixed(1) / 10);
          daemmungdata.set('ArtNr1', pNode.data.artnr);
          // daemmungdata.set('ArtNr1', '');

          if (!self.get('master')) {
            this.setSparrenabstand(pNode.data.artnr);
          }

          self.set('daemmstoffart_2', self.selectedNodesLage1[0].data.art);
          self.set('daemmstoffartnr_2', self.selectedNodesLage1[0].data.artnr);
          self.set('daemmstoffdicken_2', this.checkDickeMDE(self.selectedNodesLage1[0].data));

          bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('y', this.checkDickeMDE(pNode.data) / 10);
          bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).set('y', this.checkDickeMDE(self.selectedNodesLage1[0].data) / 10);

          self.set('daemmstoffdruckfest_2', self.selectedNodesLage1[0].data.druckfest);
          self.set('daemmstoffemod_2', self.selectedNodesLage1[0].data.emod);
          self.set('daemmstoffgew_2', self.selectedNodesLage1[0].data.gew);
          self.set('daemmstoffhersteller_2', self.selectedNodesLage1[0].data.hersteller);
          self.set('daemmstoffind_2', self.selectedNodesLage1[0].data.ind);
          self.set('daemmstoffs_10_2', self.selectedNodesLage1[0].data.s_10);
          self.set('daemmstofftext_2', self.selectedNodesLage1[0].data.text);

          // daemmungdata.set('NameDae2', ('BauderPIR ' + self.get('daemmstoffart_2')));
          daemmungdata.set('ArtNr2', self.selectedNodesLage1[0].data.artnr);
          daemmungdata.set('d_Dae2', parseFloat(self.get('daemmstoffdicken_2')) / 10);

          if (self.get('daemmstoffart_2').indexOf("MDE") !== -1) {
            console.log("mde selected on 2nd layer.");
            self.set('glLaenge', "1.18");
            self.set('mde', true);
            self.set('grundlatteMaterial', 1);
            daemmungdata.set('mde', true);
            daemmungdata.set('L_Gl', "118");
            daemmungdata.set('MatKeyTimberElementGl', 20);
            daemmungdata.set('FKLKeyTimberElementGl', 126);
            this.set('glDicke', "22.0");

            x3d.get('bauteile').findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', 2.2);
            x3d.get('bauteile').findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', 2.2);
          } else {
            console.log("mde not selected on 2nd layer.");
            self.set('glLaenge', "5.0");
            self.set('mde', false);
            self.set('grundlatteMaterial', 0);
            daemmungdata.set('mde', false);
            daemmungdata.set('L_Gl', "0");
            daemmungdata.set('MatKeyTimberElementGl', 0);
            daemmungdata.set('FKLKeyTimberElementGl', 5);
          }

          var application = self.controllerFor('application');
          application.set('daemmungInvalid', false);

          self.set('daemstoffbezeichnung', self.get('daemmstoffart_1') + ' ' + parseInt(self.get('daemmstoffdicken_1')) + ' + ' + self.get('daemmstoffart_2') + ' ' + parseInt(Number(self.selectedNodesLage1[0].data.dicke)));
          self.set('daemstoffdickegesamt', parseInt(self.get('daemmstoffdicken_1')) + parseInt(this.checkDickeMDE(self.selectedNodesLage1[0].data)));
        }
      }

      var y1 = bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).get('y');
      var y2 = bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y');

      bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).set('y', y1 + y2);
      bauteile.findBy('id', 'traufkonstruktion2').get('boxsizes').objectAt(0).set('y', y1 + y2);

      var unterkonstruktion = this.controllerFor('unterkonstruktion');
      unterkonstruktion.setSchraubenEigenschaften();
      unterkonstruktion.setHoeheUnterkonstruktion();

      x3d.set('daemmstoffDickeEingetragen', true);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));

      this.send('validation', self.get('glHoehe'), { target: { name: "glHoehe" } });
    }).observes('selectedNodesLage1'),

    watchSelectedNodesTree2: (function () {

      var self = this;

      console.log('+++++ watchSelectedNodesTree2 +++++');

      console.log(self.selectedNodesLage2[0].data.hersteller);

      self.set('daemmstoffart_2', self.selectedNodesLage2[0].data.art);
      self.set('daemmstoffart_1', self.get('daemmstoffart_1') + ' + ' + self.selectedNodesLage2[0].data.art);
      //self.set('daemmstoffartnr_2', self.selectedNodesLage2[0].data.artnr);
      self.set('daemmstoffartnr_2', '');
      self.set('daemmstoffdicken_2', this.checkDickeMDE(self.selectedNodesLage2[0].data));
      self.set('daemmstoffdruckfest_2', self.selectedNodesLage2[0].data.druckfest);
      self.set('daemmstoffemod_2', self.selectedNodesLage2[0].data.emod);
      self.set('daemmstoffgew_2', self.selectedNodesLage2[0].data.gew);
      self.set('daemmstoffhersteller_2', self.selectedNodesLage2[0].data.hersteller);
      self.set('daemmstoffind_2', self.selectedNodesLage2[0].data.ind);
      self.set('daemmstoffs_10_2', self.selectedNodesLage2[0].data.s_10);
      self.set('daemmstofftext_2', self.selectedNodesLage2[0].data.text);

      var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

      daemmungdata.set('NameDae2', self.get('daemmstoffart_2'));
      //daemmungdata.set('ArtNr2', self.get('daemmstoffartnr_2'));
      // daemmungdata.set('ArtNr2', '');
      daemmungdata.set('d_Dae2', parseFloat(self.get('daemmstoffdicken_2')) / 10);
      // daemmungdata.set('E_Dae', self.get('daemmstoffemod_1'));
      // daemmungdata.set('Sima10', self.get('daemmstoffs_10_1'));
      // daemmungdata.set('DaeDruckfest', self.get('daemmstoffdruckfest_1'));
    }).observes('selectedNodesLage2'),

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {

        if (String(this.get('daemmstoffdicken_1')).indexOf(",") !== -1) {
          this.set('daemmstoffdicken_1', this.get('daemmstoffdicken_1').replace(",", "."));
        }

        if (String(this.get('glHoehe')).indexOf(",") !== -1) {
          this.set('glHoehe', this.get('glHoehe').replace(",", "."));
        }

        if (String(this.get('glBreite')).indexOf(",") !== -1) {
          this.set('glBreite', this.get('glBreite').replace(",", "."));
        }

        if (String(this.get('glLaenge')).indexOf(",") !== -1) {
          this.set('glLaenge', this.get('glLaenge').replace(",", "."));
        }

        if (String(this.get('sAbstand')).indexOf(",") !== -1) {
          this.set('sAbstand', this.get('sAbstand').replace(",", "."));
        }

        if (String(this.get('glDicke')).indexOf(",") !== -1) {
          this.set('glDicke', this.get('glDicke').replace(",", "."));
        }
      }
    }).observes('daemmstoffdicken_1', 'glHoehe', 'glBreite', 'glLaenge', 'sAbstand'),

    actions: {

      getDaemmstoffbaum: function getDaemmstoffbaum(event) {

        var self = this;
        var application = self.controllerFor('application');

        if (application.get('bauder')) {

          console.log("bauder.");

          var testurl = "assets/data/daemmstoff_BAUDER2.json";

          var application = self.controllerFor('application');
          var x3d = application.get('model').x3ddefault.objectAt(0);
          var bauteile = x3d.get('bauteile');
          var gebaeudemasse = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);

          if (gebaeudemasse.get('schalung') === 'beton') {
            testurl = "assets/data/daemmstoff_BAUDER2beton.json";
          } else if (gebaeudemasse.get('schalung') === 'holz') {
            testurl = "assets/data/daemmstoff_BAUDER2schalung.json";
          }

          $.ajax({
            type: "get",
            dataType: "json",
            url: testurl
          }).done(function (data) {
            var core = toolsBauder.buildTree(data);
            self.set('daemmungLage1', core);
          });
        } else {

          console.log("wuerth.");

          $.ajax({
            type: "get",
            dataType: "json",
            url: "assets/data/daemmstoffliste.json"
          }).done(function (data) {
            var core = tools.buildTree(data);
            self.set('daemmungLage1', core);
          });
        }
      },

      bauderAction: function bauderAction(event) {
        var bauder = this.get('bauder');
        if (bauder) {
          this.set('bauder', false);
        } else {
          this.set('bauder', true);
        }
      },

      handleJstreeGetNode: function handleJstreeGetNode(node) {
        this.set('parentNode', node);
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }
        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          if (self.get('debug')) {
            // console.log('##### Errors #####');
            // console.log(self.errors);
          }

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            // if (self.get('daemmstoffdicken_1') > 0) {
            console.log("Validation!");
            self.set('displayErrors', false);
            application.set('daemmungInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var daemmungdata = self.controllerFor('application').get('model').daemmung.objectAt(0);

            if (self.osbSelected) {
              daemmungdata.set('glHoehe', (parseFloat(self.get('glDicke')) / 10).toFixed(1));
              daemmungdata.set('glBreite', "20.0");
            } else {
              daemmungdata.set('glHoehe', (parseFloat(self.get('glHoehe')) / 10).toFixed(1));
              daemmungdata.set('glBreite', (parseFloat(self.get('glBreite')) / 10).toFixed(1));
            }

            daemmungdata.set('L_Gl', (parseFloat(self.get('glLaenge')) * 100).toFixed(1));
            daemmungdata.set('schraubenabstaende', parseFloat(self.get('sAbstand')).toFixed(1));

            if (self.get('bauder') === false) {
              daemmungdata.set('NameDae1', self.get('daemstoffbezeichnung'));
              daemmungdata.set('d_Dae1', (parseFloat(self.get('daemstoffdickegesamt')) / 10).toFixed(1));
            } else {
              daemmungdata.set('NameDae1', self.get('daemmstoffart_1'));
            }

            daemmungdata.set('ArtNr1', self.get('daemmstoffartnr_1'));

            daemmungdata.set('NameDae2', self.get('daemmstoffart_2'));
            daemmungdata.set('ArtNr2', self.get('daemmstoffartnr_2'));
            // daemmungdata.set('d_Dae2', (parseFloat(self.get('daemmstoffdicken_2'))/10).toFixed(1));

            daemmungdata.set('E_Dae', (parseFloat(self.get('daemmstoffemod_1')) / 10).toFixed(2));
            daemmungdata.set('Sima10', parseFloat(self.get('daemmstoffs_10_1') / 10).toFixed(3));
            daemmungdata.set('DaeDruckfest', self.get('daemmstoffdruckfest_1'));
            daemmungdata.set('SelectedVarId', self.get('SelectedVarId'));

            // }
          } else {
              self.set('displayErrors', true);
              application.set('daemmungInvalid', true);
            }
        });
      },

      dsDickeIsSelected: function dsDickeIsSelected(name, value) {
        var daemmstoffDickeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('daemmstoffDickeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('daemmstoffDickeAktiv', !daemmstoffDickeAktiv);
        if (this.get('daemmstoffdicken_1') !== "") {
          var daemmstoffdicken_1 = parseFloat(this.get('daemmstoffdicken_1').replace(",", "."));
          this.set('daemmstoffdicken_1', daemmstoffdicken_1.toFixed(1));
          this.send('validation', daemmstoffdicken_1.toFixed(1), { target: { name: "daemmstoffdicken_1" } });
        }

        if (daemmstoffDickeAktiv === false) {
          document.getElementsByName('daemstoffdickegesamt')[0].setSelectionRange(0, this.get('daemstoffdickegesamt').length);
        }
      },

      dsEmodIsSelected: function dsEmodIsSelected(name, value) {
        var self = this;
        var dsEmodAktiv = self.get('dsEmodAktiv');
        self.set('dsEmodAktiv', !dsEmodAktiv);

        if (this.get('daemmstoffemod_1') !== "") {
          var daemmstoffemod_1 = parseFloat(this.get('daemmstoffemod_1').replace(",", "."));
          this.set('daemmstoffemod_1', daemmstoffemod_1.toFixed(2));
          this.send('validation', daemmstoffemod_1.toFixed(1), { target: { name: "daemmstoffemod_1" } });
        }

        if (dsEmodAktiv === false) {
          document.getElementsByName('daemmstoffemod_1')[0].setSelectionRange(0, this.get('daemmstoffemod_1').length);
        }
      },

      dsS10IsSelected: function dsS10IsSelected(name, value) {
        var self = this;
        var dsS10Aktiv = self.get('dsS10Aktiv');
        self.set('dsS10Aktiv', !dsS10Aktiv);

        if (this.get('daemmstoffs_10_1') !== "") {
          var daemmstoffs_10_1 = parseFloat(this.get('daemmstoffs_10_1').replace(",", "."));
          this.set('daemmstoffs_10_1', daemmstoffs_10_1.toFixed(2));
          this.send('validation', daemmstoffs_10_1.toFixed(1), { target: { name: "daemmstoffs_10_1" } });
        }

        if (dsS10Aktiv === false) {
          document.getElementsByName('daemmstoffs_10_1')[0].setSelectionRange(0, this.get('daemmstoffs_10_1').length);
        }
      },

      dsNameIsSelected: function dsNameIsSelected(name, value) {
        var self = this;
        var dsNameAktiv = self.get('dsNameAktiv');
        self.set('dsNameAktiv', !dsNameAktiv);

        if (dsNameAktiv === false) {
          document.getElementsByName('daemstoffbezeichnung')[0].setSelectionRange(0, this.get('daemstoffbezeichnung').length);
        }
      },

      glLaengeIsSelected: function glLaengeIsSelected(name, value) {
        var self = this;
        var glLaengeAktiv = self.get('glLaengeAktiv');
        self.set('glLaengeAktiv', !glLaengeAktiv);

        if (this.get('glLaenge') !== "") {
          var glLaenge = parseFloat(this.get('glLaenge').replace(",", "."));
          this.set('glLaenge', glLaenge.toFixed(1));
          this.send('validation', glLaenge.toFixed(1), { target: { name: "glLaenge" } });
        }

        if (glLaengeAktiv === false) {
          document.getElementsByName('glLaenge')[0].setSelectionRange(0, this.get('glLaenge').length);
        }
      },

      sAbstandIsSelected: function sAbstandIsSelected(name, value) {
        var self = this;
        var sAbstandAktiv = self.get('sAbstandAktiv');
        self.set('sAbstandAktiv', !sAbstandAktiv);

        if (this.get('sAbstand') !== "") {
          var sAbstand = parseFloat(this.get('sAbstand').replace(",", "."));
          this.set('sAbstand', sAbstand.toFixed(1));
          this.send('validation', sAbstand.toFixed(1), { target: { name: "sAbstand" } });
        }

        if (sAbstandAktiv === false) {
          document.getElementsByName('sAbstand')[0].setSelectionRange(0, this.get('sAbstand').length);
        }
      },

      glHoeheIsSelected: function glHoeheIsSelected(name, value) {
        var glhoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glhoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glhoeheAktiv', !glhoeheAktiv);
        if (this.get('glHoehe') !== "") {
          var glHoehe = parseFloat(this.get('glHoehe').replace(",", "."));
          this.set('glHoehe', glHoehe.toFixed(1));
          this.send('validation', glHoehe.toFixed(1), { target: { name: "glHoehe" } });
        }

        if (glhoeheAktiv === false) {
          document.getElementsByName('glHoehe')[0].setSelectionRange(0, this.get('glHoehe').length);
        }
      },

      glBreiteIsSelected: function glBreiteIsSelected(name, value) {
        var glbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glbreiteAktiv', !glbreiteAktiv);
        if (this.get('glBreite') !== "") {
          var glBreite = parseFloat(this.get('glBreite').replace(",", "."));
          this.set('glBreite', glBreite.toFixed(1));
          this.send('validation', glBreite.toFixed(1), { target: { name: "glBreite" } });
        }

        if (glbreiteAktiv === false) {
          document.getElementsByName('glBreite')[0].setSelectionRange(0, this.get('glBreite').length);
        }
      },

      glDickeIsSelected: function glDickeIsSelected(name, value) {
        var self = this;
        var glDickeAktiv = self.get('glDickeAktiv');
        self.set('glDickeAktiv', !glDickeAktiv);

        if (this.get('glDicke') !== "") {
          var glDicke = parseFloat(this.get('glDicke').replace(",", "."));
          this.set('glDicke', glDicke.toFixed(1));
          this.send('validation', glDicke.toFixed(1), { target: { name: "glDicke" } });
        }

        if (glDickeAktiv === false) {
          document.getElementsByName('glDicke')[0].setSelectionRange(0, this.get('glDicke').length);
        }
      },

      closeInfoFeld: function closeInfoFeld() {
        var self = this;
        self.set('infoFeldAnzeigen', false);
      },

      test: function test() {
        console.log('on-focus geht :-)');
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var unterkonstruktion = this.controllerFor('unterkonstruktion');

      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      switch (event.target.name) {
        case 'daemstoffdickegesamt':
          if (this.openFromFile || this.errors.daemstoffdickegesamt.length === 0) {
            value = parseFloat(value).toFixed(1);

            // console.log(('daemstoffdickegesamt').toUpperCase());

            bauteile.findBy('id', 'daemmung1').get('boxsizes').objectAt(0).set('y', value / 10);
            bauteile.findBy('id', 'traufkonstruktion1').get('boxsizes').objectAt(0).set('y', value / 10 + Number(bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y')));
            bauteile.findBy('id', 'traufkonstruktion2').get('boxsizes').objectAt(0).set('y', value / 10 + Number(bauteile.findBy('id', 'daemmung2').get('boxsizes').objectAt(0).get('y')));

            unterkonstruktion.setHoeheUnterkonstruktion();
            unterkonstruktion.setSchraubenEigenschaften();

            x3d.set('daemmstoffDickeEingetragen', true);
          } else {
            x3d.set('daemmstoffDickeEingetragen', false);
          }
          break;

        case 'glHoehe':
          if (this.openFromFile || this.errors.glHoehe.length === 0) {
            value = parseFloat(value).toFixed(1);

            if (self.osbSelected !== true) {
              bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', value / 10);
              bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', value / 10);
            }

            unterkonstruktion.setHoeheUnterkonstruktion();

            x3d.set('glhoeheEingetragen', true);
          } else {
            x3d.set('glhoeheEingetragen', false);
          }
          break;

        case 'glDicke':
          if (this.openFromFile || this.errors.glDicke.length === 0) {
            value = parseFloat(value).toFixed(1);

            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('y', value / 10);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('y', value / 10);

            unterkonstruktion.setHoeheUnterkonstruktion();

            x3d.set('glhoeheEingetragen', true);
          } else {
            x3d.set('glhoeheEingetragen', false);
          }
          break;

        case 'glBreite':
          if (this.openFromFile || this.errors.glBreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundlatte1').get('boxsizes').objectAt(0).set('z', value / 10);
            bauteile.findBy('id', 'grundlatte2').get('boxsizes').objectAt(0).set('z', value / 10);
            x3d.set('glbreiteEingetragen', true);
          } else {
            x3d.set('glbreiteEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    setGrundlattenmasseEingetragen: function setGrundlattenmasseEingetragen() {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      x3d.set('glhoeheEingetragen', true);
      x3d.set('glbreiteEingetragen', true);
    },

    checkDickeMDE: function checkDickeMDE(daemstoff) {
      var daemmstoffdicke = daemstoff.dicke;

      if (daemstoff.art.indexOf("MDE") !== -1) {
        daemmstoffdicke = parseFloat(daemstoff.dicke) - 22;
      }
      return daemmstoffdicke;
    },

    bauderSelected: (function () {
      var application = this.controllerFor('application');
      return application.get('bauder');
    }).property(''),

    setSparrenabstand: function setSparrenabstand(artnr) {
      var self = this;
      var application = self.controllerFor('application');
      var unterkonstruktion = self.controllerFor('unterkonstruktion');
      var unterkonstruktiondata = application.get('model').unterkonstruktion.objectAt(0);
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var gebaeudemasse = bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0);

      var ds;

      $.ajax({
        type: "get",
        dataType: "json",
        url: "assets/data/maxabstaende.json"
      }).done(function (data) {

        var value;
        ds = data.findBy('artnr', artnr);

        switch (gebaeudemasse.get('schalung')) {
          case '':
            value = ds.sparren;
            break;
          case 'holz':
            value = ds.schalung;
            break;
          case 'beton':
            value = ds.beton;
            break;
        }

        value = parseFloat(value).toFixed(0);

        unterkonstruktion.setMaxSpAbstand(value);
      });
    },

    druckweicherDaemmstoff: (function () {

      var value = true;
      var self = this;

      if (!self.daemmstoffdruckfest_1 && !self.bauder) {
        value = false;
      }

      return value;
    }).property('daemmstoffdruckfest_1'),

    hinweisDruckweicherDaemmstoff: (function () {

      var value = true;
      var self = this;

      if (!self.daemmstoffdruckfest_1 && !self.bauder && self.traufkonstruktion) {
        value = false;
      }

      return value;
    }).property('daemmstoffdruckfest_1', 'traufkonstruktion')

  });

});