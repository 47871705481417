define('m01new/routes/lasteinwirkung', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),

    model: function model() {
      return this.store.find('lasteinwirkung');
    },

    setupController: function setupController() {

      var applicationController = this.controllerFor('application');
      applicationController.miniertX3D();
      applicationController.set('currentPath', 'lasteinwirkung');
      applicationController.get('model').x3ddefault.objectAt(0).set('istDachkonstruktion', true);

      var environment = externalConfig.environments[this.controllerFor('application').get('environment')];
      var server = externalConfig[environment].server;

      $.ajax({
        type: "GET",
        url: server + 'bekommeKonfiguration',

        error: function error(_error) {
          console.log(' error');
          console.log(_error);
        }
      }).done(function (response) {

        var configdata = applicationController.model.config.objectAt(0);
        configdata.set('lastannahmenEC1ServiceURL', response.lastannahmenEC1ServiceURL);
      });

      this.controllerFor('lasteinwirkung').lastenAbfragen();
    }

  });

});