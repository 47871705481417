define('m01new/controllers/projektdaten', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  /**
   * Controller für den Projektdaten-Reiter.
   *
   * @class projektdaten
   */

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    debug: true,
    plzservice: true,
    werteAusPlzService: true,
    displayErrors: true,
    displayTooltips: false,
    bvOrtsAuswahlErforderlich: false,
    vOrtsAuswahlErforderlich: false,
    bvort: "",
    vort: "",
    orte: "",
    bvname: "",
    bvplz: "",
    bvstrasse: "",
    vname: "",
    vstrasse: "",
    vplz: "",
    vtel: "",
    vfax: "",
    vemail: "",
    bezeichnung: "",
    beschreibung: "",
    kdnum: "",
    bemerkungstext: "",
    bvOrtsListe: [],
    vOrtsListe: [],

    validations: {},

    validationsBauder: {
      bvname: {
        length: {
          minimum: 3
        }
      },
      bvstrasse: {
        length: {
          minimum: 3
        }
      },
      bvplz: {
        length: {
          minimum: 4,
          maximum: 6
        },
        format: {
          'with': /^[0-9]/
        }
      },
      bvort: {
        length: {
          minimum: 2
        }
      },
      vname: {
        length: {
          minimum: 3
        }
      },
      vstrasse: {
        length: {
          minimum: 3
        }
      },
      vplz: {
        length: {
          minimum: 4,
          maximum: 6
        },
        format: {
          'with': /^[0-9]/
        }
      },
      vort: {
        length: {
          minimum: 2
        }
      }
    },

    slzarray: [{
      id: 1,
      name: "1"
    }, {
      id: 2,
      name: "1a"
    }, {
      id: 3,
      name: "2"
    }, {
      id: 4,
      name: "2a"
    }, {
      id: 5,
      name: "3"
    }],

    init: function init() {

      var application = this.controllerFor('application');

      if (application.get('bauder')) {
        this.validations = this.validationsBauder;
        application.set('projektdatenInvalid', true);
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('bauder', true);
      }
      this._super();
    },

    setValues: function setValues(values) {

      this.set('plzservice', false);

      if (this.debug) {
        console.log("values in projektdaten: ");
      }

      this.set('bvname', values.bvname);
      this.set('bvplz', values.bvplz);
      this.set('bvort', values.bvort);
      this.set('bvstrasse', values.bvstrasse);
      this.set('vname', values.vname);
      this.set('vstrasse', values.vstrasse);
      this.set('vort', values.vort);
      this.set('vplz', values.vplz);
      this.set('vtel', values.vtel);
      this.set('vfax', values.vfax);
      this.set('vemail', values.vemail);
      this.set('bezeichnung', values.bezeichnung);
      this.set('beschreibung', values.beschreibung);
      this.set('kdnum', values.kdnum);
      this.set('bemerkungstext', values.bemerkungstext);

      this.send('storeValues');
      this.set('plzservice', true);
    },

    /**
     * Observes the Bvplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchBvplz
     */
    watchBvplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('bvplz'));
        var bvplz = self.get('bvplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (bvplz.length === 5) {

          $.get(url + bvplz, function (data) {

            console.log('data');
            console.log(data);

            self.set('bvort', '');

            var orte = [];
            var liste = [];
            var ortelength = data.length;

            self.set('bvOrtsAuswahlErforderlich', false);

            if (ortelength > 1) {
              self.set('bvOrtsAuswahlErforderlich', true);

              data.forEach(function (ortsdaten) {
                var ort = ortsdaten.split(";")[1];
                var wlz = ortsdaten.split(";")[3];
                var slz = ortsdaten.split(";")[5];
                var ndte = ortsdaten.split(";")[6];
                var hunn = ortsdaten.split(";")[7];

                liste.push({ ort: ort, wlz: wlz, slz: slz, ndte: ndte, hunn: hunn });
              });

              self.set('bvOrtsListe', liste);
            } else {
              var ortsdaten = data[0];
              var ort = ortsdaten.split(";")[1];
              var wlz = ortsdaten.split(";")[3];
              var slz = ortsdaten.split(";")[5];
              var ndte = ortsdaten.split(";")[6];
              var hunn = ortsdaten.split(";")[7];

              if (self.get('werteAusPlzService') === true) {
                self.getLasten(ort, wlz, slz, ndte, hunn);
              }
              self.set('bvort', ort);
            }

            self.send('storeValues');
          });
        }
      }
    }).observes('bvplz'),

    getLasten: function getLasten(ort, wlz, slz, ndte, hunn) {

      var self = this;
      var application = self.controllerFor('application');
      var lasteinwirkung = self.controllerFor(application.get('lasteinwirkungsReiter'));
      lasteinwirkung.set('gelaendehoehe', hunn);
      lasteinwirkung.set('slz', parseInt(self.slzarray.findBy('name', slz).id));
      lasteinwirkung.set('wlz', parseInt(wlz));
      if (Number(ndte) === 0) {
        lasteinwirkung.set('ndte', false);
      } else if (Number(ndte) === 1) {
        lasteinwirkung.set('ndte', true);
      }
      lasteinwirkung.set('geoDatenEingegeben', true);
      lasteinwirkung.set('geoDatenManuell', false);

      self.send('storeValues');
    },

    /**
     * Observes the vplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchVplz
     */
    watchVplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('vplz'));
        var vplz = self.get('vplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (vplz.length === 5) {

          $.get(url + vplz, function (data) {

            var orte = [];
            var liste = [];
            var ortelength = data.length;

            self.set('vOrtsAuswahlErforderlich', false);

            if (ortelength > 1) {
              self.set('vOrtsAuswahlErforderlich', true);

              data.forEach(function (ortsdaten) {
                var ort = ortsdaten.split(";")[1];
                var wlz = ortsdaten.split(";")[3];
                var slz = ortsdaten.split(";")[5];
                var ndte = ortsdaten.split(";")[6];
                var hunn = ortsdaten.split(";")[7];

                liste.push({ ort: ort, wlz: wlz, slz: slz, ndte: ndte, hunn: hunn });
              });

              self.set('vOrtsListe', liste);
            } else {

              var ortsdaten = data[0];
              console.log(ortsdaten);
              var ort = ortsdaten.split(";")[1];
              var slz = ortsdaten.split(";")[3];
              var wlz = ortsdaten.split(";")[5];
              var hunn = ortsdaten.split(";")[7];

              console.log(ort);
              console.log(slz);
              console.log(wlz);
              console.log(hunn);
              self.set('vort', ort);
            }
            self.send('storeValues');
          });
        }
      }
    }).observes('vplz'),

    bauderSelected: (function () {
      var application = this.controllerFor('application');
      return application.get('bauder');
    }).property(''),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');

          console.log('projektdaten validation (invalid)');
          console.log(application.get('projektdatenInvalid'));
          console.log('validation: ' + validation);

          if (validation === true) {
            self.set('displayErrors', false);
            application.set('projektdatenInvalid', false);

            console.log('validation true');

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true); // das muss immer so bleibeb, in jedem Reiter.
          } else {
              self.set('displayErrors', true);
              application.set('projektdatenInvalid', true);
            }
        });
      },

      /**
       * Stores all values from the input forms in the projektdaten store.
       *
       * @method ACTION: storeValues
       */

      storeValues: function storeValues() {

        var self = this;
        var projektdaten = this.controllerFor('application').get('model').projektdaten.objectAt(0);

        // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        // applicationdata.set('treeLoaded', false);
        // applicationdata.set('initialized', false);
        //
        // var application = self.controllerFor('application');
        // application.set('verbindungsmittelInvalid', true);

        console.log('projektdaten storeValues');

        self.controllerFor('projektdaten').set('bname', 'Horst');
        projektdaten.set('bvname', self.get('bvname').replace(",", "."));
        projektdaten.set('bvplz', self.get('bvplz').replace(",", "."));
        projektdaten.set('bvstrasse', self.get('bvstrasse').replace(",", "."));
        projektdaten.set('bvort', self.get('bvort').replace(",", "."));
        projektdaten.set('vname', self.get('vname').replace(",", "."));
        projektdaten.set('vstrasse', self.get('vstrasse').replace(",", "."));
        projektdaten.set('vplz', self.get('vplz').replace(",", "."));
        projektdaten.set('vort', self.get('vort').replace(",", "."));
        projektdaten.set('vtel', self.get('vtel').replace(",", "."));
        projektdaten.set('vfax', self.get('vfax').replace(",", "."));
        projektdaten.set('vemail', self.get('vemail').replace(",", "."));
        projektdaten.set('bezeichnung', self.get('bezeichnung'));

        projektdaten.set('beschreibung', self.get('beschreibung'));
        projektdaten.set('kdnum', self.get('kdnum').replace(",", "."));
        projektdaten.set('bemerkungstext', self.get('bemerkungstext'));

        this.send('validation');
      },

      toggleTextArea: function toggleTextArea() {
        $('#bemerkungenTextAreaID').toggleClass('fullsize');

        if ($('#bemerkungenTextAreaID').offset().top < $('#threedee').offset().top + $('#x3d').height() + 20) {
          var newMargin = $('#threedee').offset().top + $('#x3d').height() + 20 - $('#bemerkungenTextAreaID').offset().top;
          $('#bemerkungenArea').css('margin-top', newMargin);
        }
      },

      setOrt: function setOrt(ort, wlz, slz, ndte, hunn, ortsTyp) {

        var self = this;

        if (self.debug) {
          console.log('ortsdaten aus setOrt');
          console.log('ort: ' + ort);
          console.log('slz: ' + slz);
          console.log('wlz: ' + wlz);
          console.log('ndte: ' + ndte);
          console.log('hunn: ' + hunn);
        }

        if (ortsTyp === 'bvort' && self.get('werteAusPlzService') === true) {
          self.getLasten(ort, wlz, slz, ndte, hunn);
        }

        self.set(ortsTyp, ort);

        var projektdaten = this.controllerFor('application').get('model').projektdaten.objectAt(0);
        projektdaten.set(ortsTyp, self.get(ortsTyp).replace(",", "."));

        self.send('validation', self.get(ortsTyp), { target: { name: ortsTyp } });
      },

      closeBvOrtsAuswahlfeld: function closeBvOrtsAuswahlfeld() {
        var self = this;
        self.set('bvOrtsAuswahlErforderlich', false);
      },

      bvnameIsSelected: function bvnameIsSelected() {
        document.getElementsByName('bvname')[0].setSelectionRange(0, this.get('bvname').length);
      },

      bvstrasseIsSelected: function bvstrasseIsSelected() {
        document.getElementsByName('bvstrasse')[0].setSelectionRange(0, this.get('bvstrasse').length);
      },

      bvplzIsSelected: function bvplzIsSelected() {
        document.getElementsByName('bvplz')[0].setSelectionRange(0, this.get('bvplz').length);
      },

      bvortIsSelected: function bvortIsSelected() {
        document.getElementsByName('bvort')[0].setSelectionRange(0, this.get('bvort').length);
      },

      bezeichnungIsSelected: function bezeichnungIsSelected() {
        document.getElementsByName('bezeichnung')[0].setSelectionRange(0, this.get('bezeichnung').length);
      },

      beschreibungIsSelected: function beschreibungIsSelected() {
        document.getElementsByName('beschreibung')[0].setSelectionRange(0, this.get('beschreibung').length);
      },

      vnameIsSelected: function vnameIsSelected() {
        document.getElementsByName('vname')[0].setSelectionRange(0, this.get('vname').length);
      },

      kdnumIsSelected: function kdnumIsSelected() {
        document.getElementsByName('kdnum')[0].setSelectionRange(0, this.get('kdnum').length);
      },

      vstrasseIsSelected: function vstrasseIsSelected() {
        document.getElementsByName('vstrasse')[0].setSelectionRange(0, this.get('vstrasse').length);
      },

      vplzIsSelected: function vplzIsSelected() {
        document.getElementsByName('vplz')[0].setSelectionRange(0, this.get('vplz').length);
      },

      vortIsSelected: function vortIsSelected() {
        document.getElementsByName('vort')[0].setSelectionRange(0, this.get('vort').length);
      },

      vtelIsSelected: function vtelIsSelected() {
        document.getElementsByName('vtel')[0].setSelectionRange(0, this.get('vtel').length);
      },

      vfaxIsSelected: function vfaxIsSelected() {
        document.getElementsByName('vfax')[0].setSelectionRange(0, this.get('vfax').length);
      },

      vemailIsSelected: function vemailIsSelected() {
        document.getElementsByName('vemail')[0].setSelectionRange(0, this.get('vemail').length);
      },

      bemerkungstextIsSelected: function bemerkungstextIsSelected() {
        document.getElementsByName('bemerkungstext')[0].setSelectionRange(0, this.get('bemerkungstext').length);
      }

    },

    collapse: (function () {
      return true;
    }).property(''),

    bauder: (function () {

      var application = this.controllerFor('application');

      return application.get('bauder');
    }).property('')

  });

});